@tailwind base;
@tailwind components;
@tailwind utilities; 

@layer utilities {
    .scrollbar::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
  
    .scrollbar::-webkit-scrollbar-track {
      border-radius: 100vh;
      background: #10B582;
    }
  
    .scrollbar::-webkit-scrollbar-thumb {
      background: #FA9F42;
      border-radius: 100vh;
      border: 3px solid #f6f7ed;
    }
  
    .scrollbar::-webkit-scrollbar-thumb:hover {
      background: #c0a0b9;
    }
  }


